












import MonitoringOverview from "@/components/Monitoring/MonitoringOverview.vue";
import PageTemplate from "@/components/PageTemplate.vue";
import { RouteName } from "@/router/types";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    PageTemplate,
    MonitoringOverview,
  },
})
export default class MonitoringOverviewView extends Vue {
  RouteName = RouteName;
}
