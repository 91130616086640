


























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MenuItem extends Vue {
  @Prop() readonly title!: string;
  @Prop() readonly data!: string;
  @Prop() readonly tooltip!: string;
  @Prop() readonly isLoading!: boolean;
}
