
















































































































































import { Component, Vue } from "vue-property-decorator";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import moment from "moment";
import { Action, Getter } from "vuex-class";
import {
  ChatActions,
  MonitoringAction,
  MonitoringGetter,
  UsersStatsRequest,
} from "./monitoring-store/types";
import { RouteName } from "@/router/types";
import {
  Chart,
  ChartConfiguration,
  ChartData,
  ChartOptions,
  ChartType,
  registerables,
} from "chart.js";
import { Namespace } from "@/store/types";
import StatsPlaceholder from "./StatsPlaceholder.vue";

Chart.register(...registerables);

@Component({
  components: { VueCtkDateTimePicker, StatsPlaceholder },
})
export default class MonitoringOverview extends Vue {
  @Action(`${Namespace.Monitoring}/${MonitoringAction.FetchConcurrentUsers}`)
  fetchConcurrentUsers!: (data: UsersStatsRequest) => Promise<void>;
  @Action(`${Namespace.Monitoring}/${MonitoringAction.FetchUniqueUsers}`)
  fetchUniqueUsers!: (data: UsersStatsRequest) => Promise<void>;
  @Action(`${Namespace.Monitoring}/${MonitoringAction.FetchRecurringUsers}`)
  fetchRecurringUsers!: (data: UsersStatsRequest) => Promise<void>;
  @Action(`${Namespace.Monitoring}/${MonitoringAction.FetchLinkClicks}`)
  fetchLinkClicks!: (data: UsersStatsRequest) => Promise<void>;
  @Action(`${Namespace.Monitoring}/${MonitoringAction.FetchChatActions}`)
  fetchChatActions!: (data: UsersStatsRequest) => Promise<void>;
  @Action(`${Namespace.Monitoring}/${MonitoringAction.FetchActiveUsersStat}`)
  fetchActiveUsersStat!: (data: UsersStatsRequest) => Promise<void>;

  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.RecurringUsersData}`)
  readonly recurringUsersData!: number;

  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.ConcurrentUsersDataTime}`)
  readonly concurrentUsersDataTime!: string[];
  @Getter(
    `${Namespace.Monitoring}/${MonitoringGetter.ConcurrentUsersDataValue}`
  )
  readonly concurrentUsersDataValue!: number[];

  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.UniqueUsersDataTime}`)
  readonly uniqueUsersDataTime!: string[];
  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.UniqueUsersDataValue}`)
  readonly uniqueUsersDataValue!: number[];
  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.UniqueUsersStat}`)
  readonly uniqueUsersStat!: number;

  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.LinkClicksDataTime}`)
  readonly linkClicksDataTime!: string[];
  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.LinkClicksDataValue}`)
  readonly linkClicksDataValue!: number[];

  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.ActiveUsersData}`)
  readonly activeUsersData!: number;

  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.ChatActionUpubValue}`)
  readonly chatActionUpubValue!: number[];
  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.ChatActionStartValue}`)
  readonly chatActionStartValue!: number[];
  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.ChatActionEndValue}`)
  readonly chatActionEndValue!: number[];
  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.ChatActionReactionValue}`)
  readonly chatActionReactionValue!: number[];
  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.ChatActionReplyValue}`)
  readonly chatActionReplyValue!: number[];
  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.ChatActionVoteValue}`)
  readonly chatActionVoteValue!: number[];

  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.ChatActionUpub}`)
  readonly chatActionUpub!: number[];
  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.ChatActionStart}`)
  readonly chatActionStart!: number[];
  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.ChatActionEnd}`)
  readonly chatActionEnd!: number[];
  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.ChatActionReaction}`)
  readonly chatActionReaction!: number[];
  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.ChatActionReply}`)
  readonly chatActionReply!: number[];
  @Getter(`${Namespace.Monitoring}/${MonitoringGetter.ChatActionVote}`)
  readonly chatActionVote!: number[];

  RouteName = RouteName;

  isLoading = false;
  timeFrame = this.$t("Today");
  date = { start: "", end: "" };
  maxDate = moment().toISOString();
  minDate = moment().subtract(1, "year").toISOString();
  today = moment();

  // TODO: Use Getters
  totalLinkClicks = 0;
  peakConcurrentUsers = 0;
  pollVoteStat = 0;
  activeUsersStat = 0;

  timeRangeModal = false;

  _chartUniqueUsers: Chart | null = null;
  _chartConcurrentUsers: Chart | null = null;
  _chartLinkClicks: Chart | null = null;
  _chartChatActions: Chart | null = null;
  _chartChatActionsPie: Chart | null = null;
  _chartPollActions: Chart | null = null;

  $refs!: {
    uniqueUsersChart: HTMLCanvasElement;
    concurrentUsersChart: HTMLCanvasElement;
    linkClicksChart: HTMLCanvasElement;
    chatActionsChart: HTMLCanvasElement;
    chatActionsPieChart: HTMLCanvasElement;
    pollActionPieChart: HTMLCanvasElement;
  };

  createChartData() {
    this._chartUniqueUsers?.destroy();
    this._chartConcurrentUsers?.destroy();
    this._chartLinkClicks?.destroy();
    this._chartChatActions?.destroy();
    this._chartChatActionsPie?.destroy();
    this._chartPollActions?.destroy();
    this.createCharts(
      {
        datasets: [
          {
            data: this.uniqueUsersDataValue,
            backgroundColor: "lightblue",
            borderColor: "lightblue",
            pointBorderWidth: 0,
            pointBackgroundColor: "lightblue",
          },
        ],
        labels: this.uniqueUsersDataTime,
      },
      {
        datasets: [
          {
            data: this.concurrentUsersDataValue,
            pointBorderWidth: 0,
            backgroundColor: "lightblue",
            borderColor: "lightblue",
            pointBackgroundColor: "lightblue",
          },
        ],
        labels: this.concurrentUsersDataTime,
      },
      {
        datasets: [
          {
            data: this.linkClicksDataValue,
            pointBorderWidth: 0,
            backgroundColor: "lightblue",
            borderColor: "lightblue",
            pointBackgroundColor: "lightblue",
          },
        ],
        labels: this.linkClicksDataTime,
      },
      {
        datasets: [
          {
            label: this.$tc("monitoringOverview.upub"),
            data: this.chatActionUpub,
            pointBorderWidth: 0,
            backgroundColor: "lightblue",
            borderColor: "lightblue",
            pointBackgroundColor: "lightblue",
          },
          {
            label: this.$tc("monitoringOverview.start"),
            data: this.chatActionStart,
            pointBorderWidth: 0,
            backgroundColor: "lightgreen",
            borderColor: "lightgreen",
            pointBackgroundColor: "lightgreen",
          },
          {
            label: this.$tc("monitoringOverview.end"),
            data: this.chatActionEnd,
            pointBorderWidth: 0,
            backgroundColor: "red",
            borderColor: "red",
            pointBackgroundColor: "red",
          },
          {
            label: this.$tc("monitoringOverview.reaction"),
            data: this.chatActionReaction,
            pointBorderWidth: 0,
            backgroundColor: "green",
            borderColor: "green",
            pointBackgroundColor: "green",
          },
          {
            label: this.$tc("monitoringOverview.reply"),
            data: this.chatActionReply,
            pointBorderWidth: 0,
            backgroundColor: "purple",
            borderColor: "purple",
            pointBackgroundColor: "purple",
          },
          {
            label: this.$tc("monitoringOverview.vote"),
            data: this.chatActionVote,
            pointBorderWidth: 0,
            backgroundColor: "orange",
            borderColor: "orange",
            pointBackgroundColor: "orange",
          },
        ],
      },
      {
        datasets: [
          {
            data: [
              this.chatActionUpubValue.reduce(
                (partialSum, a) => partialSum + a,
                0
              ),
              this.chatActionStartValue.reduce(
                (partialSum, a) => partialSum + a,
                0
              ),
              this.chatActionEndValue.reduce(
                (partialSum, a) => partialSum + a,
                0
              ),
              this.chatActionReactionValue.reduce(
                (partialSum, a) => partialSum + a,
                0
              ),
              this.chatActionReplyValue.reduce(
                (partialSum, a) => partialSum + a,
                0
              ),
              this.chatActionVoteValue.reduce(
                (partialSum, a) => partialSum + a,
                0
              ),
            ],
            backgroundColor: [
              "lightblue",
              "lightgreen",
              "red",
              "green",
              "purple",
              "orange",
            ],
          },
        ],
        labels: [
          this.$t("monitoringOverview.upub"),
          this.$t("monitoringOverview.start"),
          this.$t("monitoringOverview.end"),
          this.$t("monitoringOverview.reaction"),
          this.$t("monitoringOverview.reply"),
          this.$t("monitoringOverview.vote"),
        ],
      },
      {
        datasets: [
          {
            data: [
              this.chatActionVoteValue.reduce(
                (partialSum, a) => partialSum + a,
                0
              ),
              this.chatActionStartValue.reduce(
                (partialSum, a) => partialSum + a,
                0
              ),
              this.chatActionEndValue.reduce(
                (partialSum, a) => partialSum + a,
                0
              ),
            ],
            backgroundColor: ["lightblue", "lightgreen", "red"],
          },
        ],
        labels: [
          this.$t("monitoringOverview.vote"),
          this.$t("monitoringOverview.start"),
          this.$t("monitoringOverview.end"),
        ],
      }
    );
  }

  createCharts(
    uniqueUsersData: ChartData,
    concurrentUsersData: ChartData,
    linkClicksData: ChartData,
    chatActionsData: ChartData,
    chatActionsPieData: ChartData,
    pollActionPieData: ChartData
  ): void {
    this._chartUniqueUsers = new Chart(
      this.$refs.uniqueUsersChart,
      this.chartOptions(
        "bar",
        this.$tc("monitoringOverview.uniqueUsers"),
        uniqueUsersData
      )
    );
    this._chartConcurrentUsers = new Chart(
      this.$refs.concurrentUsersChart,
      this.chartOptions(
        "line",
        this.$tc("monitoringOverview.concurrentUsers"),
        concurrentUsersData
      )
    );
    this._chartLinkClicks = new Chart(
      this.$refs.linkClicksChart,
      this.chartOptions(
        "bar",
        this.$tc("monitoringOverview.linkClicks"),
        linkClicksData
      )
    );
    this._chartChatActions = new Chart(
      this.$refs.chatActionsChart,
      this.chartOptions(
        "bar",
        this.$tc("monitoringOverview.chatActions"),
        chatActionsData
      )
    );
    this._chartChatActionsPie = new Chart(
      this.$refs.chatActionsPieChart,
      this.chartOptions(
        "pie",
        this.$tc("monitoringOverview.chatActions"),
        chatActionsPieData
      )
    );
    this._chartPollActions = new Chart(
      this.$refs.pollActionPieChart,
      this.chartOptions(
        "pie",
        this.$tc("monitoringOverview.pollEvents"),
        pollActionPieData
      )
    );

    uniqueUsersData.datasets[0].data.every((item) => item === 0)
      ? this.chartHasNoData(this._chartUniqueUsers)
      : null;
    concurrentUsersData.datasets[0].data.every((item) => item === 0)
      ? this.chartHasNoData(this._chartConcurrentUsers)
      : null;
    linkClicksData.datasets[0].data.every((item) => item === 0)
      ? this.chartHasNoData(this._chartLinkClicks)
      : null;
    chatActionsData.datasets[0].data.every((item) => item === 0)
      ? this.chartHasNoData(this._chartChatActions)
      : null;
    chatActionsPieData.datasets[0].data.every((item) => item === 0)
      ? this.chartHasNoData(this._chartChatActionsPie)
      : null;
    pollActionPieData.datasets[0].data.every((item) => item === 0)
      ? this.chartHasNoData(this._chartPollActions)
      : null;
  }

  chartHasNoData(chart: Chart) {
    // No data is present
    var ctx = chart.ctx;
    var width = chart.width;
    var height = chart.height;
    var title = (chart.options.plugins?.title?.text as string) || "";
    chart.destroy();

    ctx.canvas.width = width;
    ctx.canvas.height = height;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.font =
      "normal normal bold 12px 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";
    ctx.fillStyle = "#787878";
    ctx.fillText(title, width / 2, 18); // <====   ADDS TITLE
    ctx.fillText(
      this.$tc("monitoringOverview.noDataChart"),
      width / 2,
      height / 2
    );
  }

  chartOptions(type: ChartType, title: string, data: ChartData) {
    const options: ChartOptions =
      type === "pie"
        ? {
            plugins: {
              title: {
                display: true,
                text: title,
              },
              tooltip: {
                intersect: false,
              },
            },
          }
        : {
            elements: {
              point: {
                radius: 0,
              },
              line: {
                tension: 0.1,
              },
            },
            scales: {
              y: {
                beginAtZero: true,
              },
              xAxes: {
                ticks: {
                  minRotation: 0,
                  autoSkip: true,
                  maxTicksLimit: 10,
                },
              },
            },
            plugins: {
              title: {
                display: true,
                text: title,
              },
              legend: {
                display: false,
              },
              tooltip: {
                intersect: false,
              },
            },
          };
    const chartOptions: ChartConfiguration = {
      type: type,
      data: data,
      options: options,
    };
    return chartOptions;
  }

  async created() {
    this.callApis(
      moment(this.today.clone().startOf("day")).format("x").toString(),
      moment(this.today.clone().endOf("day")).format("x").toString(),
      "30m"
    );
  }
  async callApis(startDate: string, endDate: string, aggwindow: string) {
    this.isLoading = true;

    await Promise.all([
      this.fetchConcurrentUsers({
        start: startDate,
        end: endDate,
        aggwindow: aggwindow,
      }),
      this.fetchUniqueUsers({
        start: startDate,
        end: endDate,
        aggwindow: aggwindow,
      }),
      this.fetchUniqueUsers({
        start: startDate,
        end: endDate,
      }),
      this.fetchRecurringUsers({
        start: startDate,
        end: endDate,
      }),
      this.fetchLinkClicks({
        start: startDate,
        end: endDate,
        aggwindow: aggwindow,
      }),
      this.fetchActiveUsersStat({
        start: startDate,
        end: endDate,
        aggwindow: aggwindow,
      }),

      this.fetchChatActions({
        start: startDate,
        end: endDate,
        aggwindow: aggwindow,
        action: ChatActions.upub,
      }),
      this.fetchChatActions({
        start: startDate,
        end: endDate,
        aggwindow: aggwindow,
        action: ChatActions.start,
      }),
      this.fetchChatActions({
        start: startDate,
        end: endDate,
        aggwindow: aggwindow,
        action: ChatActions.end,
      }),
      this.fetchChatActions({
        start: startDate,
        end: endDate,
        aggwindow: aggwindow,
        action: ChatActions.reaction,
      }),
      this.fetchChatActions({
        start: startDate,
        end: endDate,
        aggwindow: aggwindow,
        action: ChatActions.reply,
      }),
      this.fetchChatActions({
        start: startDate,
        end: endDate,
        aggwindow: aggwindow,
        action: ChatActions.vote,
      }),
    ]);
    this.createChartData();

    this.totalLinkClicks = this.linkClicksDataValue.length
      ? this.linkClicksDataValue.reduce((partialSum, a) => partialSum + a, 0)
      : 0;
    this.peakConcurrentUsers = this.concurrentUsersDataValue.length
      ? Math.max(...this.concurrentUsersDataValue)
      : 0;
    this.pollVoteStat = this.chatActionVoteValue.reduce(
      (partialSum, a) => partialSum + a,
      0
    );
    this.activeUsersStat =
      parseFloat(
        ((this.activeUsersData / this.uniqueUsersStat) * 100).toFixed(1)
      ) || 0;

    this.isLoading = false;
  }

  async selectionChanged(selection: string) {
    if (selection == this.$t("Custom")) {
      this.timeRangeModal = true;
      this.timeFrame = selection;
    } else if (selection == this.$t("Today")) {
      this.timeFrame = this.$t("Today");
      this.callApis(
        moment(this.today.clone().startOf("day")).format("x").toString(),
        moment(this.today.clone().endOf("day")).format("x").toString(),
        "30m"
      );
    } else if (selection == this.$t("This week")) {
      const fromDate = this.today.clone().startOf("isoWeek");
      const toDate = this.today.clone().endOf("isoWeek");
      this.timeFrame = selection;
      this.callApis(
        fromDate.format("x").toString(),
        toDate.format("x").toString(),
        "1h"
      );
    } else if (selection == this.$t("This month")) {
      const fromDate = this.today.clone().startOf("month");
      const toDate = this.today.clone().endOf("month");
      this.timeFrame = selection;
      this.callApis(
        fromDate.format("x").toString(),
        toDate.format("x").toString(),
        "6h"
      );
    } else if (selection == this.$t("This year")) {
      const fromDate = this.today.clone().startOf("year");
      const toDate = this.today.clone().endOf("year");
      this.timeFrame = selection;
      this.callApis(
        fromDate.format("x").toString(),
        toDate.format("x").toString(),
        "1d"
      );
    } else {
      if (this.date.start && this.date.end) {
        const daysDifference = moment(this.date.end).diff(
          this.date.start,
          "days"
        );
        const aggwindow: string =
          daysDifference <= 1
            ? "30m"
            : daysDifference <= 7
            ? "1h"
            : daysDifference <= 31
            ? "6h"
            : "1d";

        this.timeFrame = this.date.start + " - " + this.date.end;
        this.callApis(
          moment(this.date.start).startOf("day").format("x").toString(),
          moment(this.date.end).endOf("day").format("x").toString(),
          aggwindow
        );
      } else {
        this.timeFrame = this.date.start;
        this.callApis(
          moment(this.date.start).format("x").toString(),
          moment(this.date.start).endOf("day").format("x").toString(),
          "30m"
        );
      }
      this.timeRangeModal = false;
    }
  }
}
